import '../../styles/slick-arrows.css';
import '../../styles/classes-slider.css';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Slider from "react-slick";
import { Colors } from "../../constants/colors";
import ClassTile from "./ClassTile";
import { navigate } from 'gatsby';


export default function ClassesSlider({classes, style, sectionTitle, openClass, showMore = true, landscape, playButton, bottomTitle, index}) {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		swipe: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: false,
					dots: true
				}
			}
		]
	};


	return (
		<div className="slider-container mb-10">
			{sectionTitle &&
				<div className="slider-title mb-6">
					<h2 className="font-hn">{sectionTitle}</h2>
				</div>
			}
			<Slider {...settings} >
				{classes && classes.map((item, index) => (
					<ClassTile 
						key={index} 
						cover={item.cover?.url || false} 
						name={item.name}
						objective={landscape ? false : item.objective}
						author={item.instructor?.name}
						id={item.id}
						nameFont={item.nameFont}
						onClick={() => navigate(`/class/${item.id}`)}
						bottomTitle={bottomTitle}
						playButton={playButton}
					/>
				))}
			</Slider>
    	</div>
	)
}

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
		className={`${className} classes-slider-arrow right`}
		onClick={onClick}
		>
			<ChevronRightIcon />
		</div>
	);
}
function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={`${className} classes-slider-arrow left`}
			onClick={onClick}
		>
			<ChevronLeftIcon />
		</div>
	);
}