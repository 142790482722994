import React, { useEffect, useState } from "react";
import api, { setClientToken } from "./api";

export const getPageData = async (slug) => {
	const response = await api.get(`pages?slug=${slug}`);
	if(response.status !== 200) return;
	return response.data;
}

export const getActiveClasses = async () => {
	return await api.get('classes/active');
}

export const getClass = async (id) => {
	const response = await api.get(`classes/${id}`);
	if(response.status !== 200) return;
	return response.data;
}

export const useFetch = (url) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const windowGlobal = typeof window !== 'undefined' && window
	const token = windowGlobal ? windowGlobal.localStorage.getItem('userToken') : null;

	if(token) setClientToken(token);

	useEffect(() => {
		setLoading(true);
		api.get(url).then(res => {
			setData(res.data);
			setLoading(false);
		}).catch(e => {
			setError(e);
			setLoading(false);
		});
	}, [url]);

	return {data, loading, error};
}

export default {
	getPageData,
	getActiveClasses,
	getClass,
	useFetch
}