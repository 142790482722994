import '../../styles/class-tile.css';
import React from "react";
import { Colors } from "../../constants/colors";

export default function ClassTile ({children, style, id, name, nameFont, author, objective, cover, openClass, bottomTitle, playButton, onClick, noMargin, ...others}) {
	const styles = {
		classTile: {
			flex: 1,
			flexDirection: 'column',
			display: 'flex',
			//height: 382,
			position: 'relative',
			backgroundColor: Colors.grey20,
			backgroundImage: `url(${cover})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			borderRadius: 12,
			// overflow: 'hidden',
			marginRight: noMargin ? 0 : 20,
			width: 'auto',
			cursor: 'pointer'
		},
		bottomGradient: {
			height: '100%'
		},
		blurView: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			height: '22%',
			justifyContent: 'center',
		},
		classInfo: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			padding: 15,
			textAlign: 'center',
		},
		title: {
			opacity: 1,
			//fontSize: '2.2rem',
			fontWeight: 800,
			// textTransform: 'uppercase',
			lineHeight: 1.1,
			// display: 'flex',
			// justifyContent: 'center',
			// flexDirection: 'column',
			// minHeight: 74,
			padding: '0px 5px'
		},
		subtitle: {
			marginTop: 20,
			minHeight: 48,
			fontSize: 14,
			lineHeight: 1.4
		},
		bottomTitle: {
			backgroundColor: "#222",
			//height: 30,
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			padding: 10
		},
		playContainer: {
			position: 'absolute',
			zIndex: 9,
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		playButton: {
			backgroundColor: 'rgba(0,0,0,0.5)',
			borderRadius: 100,
			width: 40,
			height: 40,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	};


	return (
		<div style={styles.classTile}
			className="class-tile h-[250px] md:h-[382px] mb-6 md:mb-0"
			onClick={onClick}
		>
			{playButton &&
				<div style={styles.playContainer}>
					<div style={styles.playButton} >
						{/* <Ionicons name="play-sharp" size={20} color={"#fff"} style={{marginLeft: 4}} /> */} PLAY
					</div>
				</div>
			}
			
				
							
						
					{!bottomTitle &&
						<div style={styles.classInfo}>
							{/* {nameFont ? <img src={nameFont?.url} style={{width: '100%', maxWidth: 220, margin: '0 auto'}}  /> : <div style={styles.title} >{name}</div> } */}
							<div className="font-hn-ex font-bold text-[20px] md:text-[28px]" style={styles.title} >{name}</div>
							{objective && <div style={styles.subtitle} className="text-gray-100" >{objective}</div>}
						</div>
					}
			{/* {bottomTitle &&
				<View style={styles.bottomTitle}>
					<Text white center text90M style={styles.bottomTitleName} >{name}</Text>
				</View>
			} */}
		</div>
	);
};

